/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */

import { MouseEvent, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { getCurrentUser } from '@aws-amplify/auth';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import ConfirmationModal from '@/dls/ConfirmationModal/ConfirmationModal';
import { useConfirm } from '@/dls/ConfirmationModal/hooks';
import Link from '@/dls/Link/Link';
import Spinner from '@/dls/Spinner/Spinner';
import { Khatma, KhatmaDocument, Part, ReservePartDocument } from '@/graphql/generated';
import fillParts from '@/utils/fill-parts';

type Props = {
  khatmaId: string;
};

// TODO: move this to a utils file
export const buildLink = (input: {
  khatmaType: Khatma['type'];
  khatmaId: string;
  partId: string;
}) => {
  switch (input.khatmaType) {
    case 'HIZB': {
      return `/hizb/${parseInt(input.partId, 10)}`;
    }
    case 'JUZ': {
      return `/juz/${parseInt(input.partId, 10)}`;
    }
    case 'PAGE': {
      return `/page/${parseInt(input.partId, 10)}`;
    }
    case 'SURA': {
      return `/sura/${parseInt(input.partId, 10)}`;
    }
    default: {
      return '';
    }
  }
};

const KhatmaContainer = (props: Props) => {
  const { khatmaId } = props;
  const { t } = useTranslation('common');
  const confirm = useConfirm();
  const router = useRouter();
  const [profileId, setProfileId] = useState<string>('');
  const [progress, setProgress] = useState<number | null>(null);
  const [parts, setParts] = useState<Part[]>([]);

  const { loading, data, refetch } = useQuery(KhatmaDocument, {
    variables: {
      khatmaId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      let total = 0;
      if (data.khatma.type === 'JUZ') {
        total = 30;
      } else if (data.khatma.type === 'HIZB') {
        total = 60;
      } else if (data.khatma.type === 'SURA') {
        total = 114;
      } else if (data.khatma.type === 'PAGE') {
        total = 604;
      }
      setProgress(
        ((data.khatma.parts?.filter((part) => part.status === 'COMPLETED').length || 0) / total) *
          100,
      );

      setParts(
        fillParts({ currentParts: data.khatma.parts, khatmaId, khatmaType: data.khatma.type }),
      );
    },
  });

  const [reservePart, { loading: reservePartLoading }] = useMutation(ReservePartDocument);

  useEffect(() => {
    if (!profileId) {
      getCurrentUser()
        .then((user) => {
          setProfileId(user.username);
        })
        .catch((error) => console.error(error));
    }
  }, [profileId, setProfileId]);

  const onClick = async (
    event: MouseEvent<Element, globalThis.MouseEvent>,
    part: Part,
    link: string,
  ) => {
    if (part.status === 'RESERVED') {
      return;
    }
    event.preventDefault();
    const isConfirmed = await confirm({
      confirmText: t('common:reserve.action'),
      cancelText: t('common:cancel'),
      title:
        data.khatma.type === 'SURA'
          ? `${t('common:reserve.action')} ${t('common:surah')} '${t(
              `common:sura.${parseInt(part.partId, 10)}`,
              {
                number: parseInt(part.partId, 10),
              },
            )}'`
          : t('common:reserve.title', {
              type: t(`common:khatma-types.${data.khatma.type}`),
              number: parseInt(part.partId, 10),
            }),
      description: t('common:reserve.description', {
        type: t(`common:khatma-types.${data.khatma.type}`),
        number: parseInt(part.partId, 10),
      }),
    });
    if (isConfirmed) {
      reservePart({
        variables: {
          input: {
            khatmaId,
            partId: part.partId,
          },
        },
        onCompleted: () => {
          router.push(link);
          refetch();
        },
      });
    }
  };

  const renderItem = (part: Part, type: Khatma['type']) => {
    const link = `${buildLink({
      khatmaId,
      khatmaType: type,
      partId: part.partId,
    })}?khatmaId=${khatmaId}`;
    if (part.status === 'OPEN') {
      return (
        <Link href={link} onClick={(e) => onClick(e, part, link)}>
          {type === 'SURA'
            ? `${t('common:surah')} ${t(`common:sura.${parseInt(part.partId, 10)}`, {
                number: parseInt(part.partId, 10),
              })} ${t('common:is-available')}`
            : t(`common:part.${type}.OPEN`, {
                number: parseInt(part.partId, 10),
              })}
        </Link>
      );
    }
    if (part.status === 'COMPLETED') {
      return (
        <>
          {type === 'SURA'
            ? `${t('common:surah')} ${t(`common:sura.${parseInt(part.partId, 10)}`, {
                number: parseInt(part.partId, 10),
              })} ${t('common:is-completed-by', { name: part.profile?.name })}`
            : t(`common:part.${type}.COMPLETED`, {
                number: parseInt(part.partId, 10),
                name: part.profile?.name,
              })}
        </>
      );
    }

    if (part.status === 'RESERVED') {
      if (part.profileId === profileId) {
        return (
          <Link href={link} onClick={(e) => onClick(e, part, link)}>
            {type === 'SURA'
              ? `${t('common:surah')} ${t(`common:sura.${parseInt(part.partId, 10)}`, {
                  number: parseInt(part.partId, 10),
                })} ${t('common:is-reserved-by', { name: part.profile?.name })}`
              : t(`common:part.${type}.RESERVED`, {
                  number: parseInt(part.partId, 10),
                  name: part.profile?.name,
                })}
          </Link>
        );
      }
      return (
        <>
          {type === 'SURA'
            ? `${t('common:surah')} ${t(`common:sura.${parseInt(part.partId, 10)}`, {
                number: parseInt(part.partId, 10),
              })} ${t('common:is-reserved-by', { name: part.profile?.name })}`
            : t(`common:part.${type}.RESERVED`, {
                number: parseInt(part.partId, 10),
                name: part.profile?.name,
              })}
        </>
      );
    }

    return <></>;
  };

  return (
    <div>
      {(loading || reservePartLoading) && <Spinner />}
      <ConfirmationModal />
      {data && (
        <div>
          <h2>
            {t('common:khatma-name')}: {data.khatma.name}
          </h2>
          {progress !== null && (
            <h2>
              {t('common:khatma-progress')}: {Math.round(progress)}%
            </h2>
          )}
          <ul>
            {parts.map((part) => {
              return <li key={part.partId}>{renderItem(part, data.khatma.type)}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default KhatmaContainer;
