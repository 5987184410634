import { useEffect } from 'react';

import { getCurrentUser } from '@aws-amplify/auth';
import { useRouter } from 'next/router';

/**
 * Redirects to the login page if the user is not logged in.
 */
const useRequireAuth = () => {
  const router = useRouter();

  useEffect(() => {
    getCurrentUser().catch(() => router.replace('/login'));
    // if (!isLoggedIn()) {
    //   router.replace('/login');
    // }
  }, [router]);
};

export default useRequireAuth;
