/* eslint-disable react-func/max-lines-per-function */ // FIXME:
import { Khatma, Part } from '@/graphql/generated';

const fillParts = (params: {
  currentParts: Part[];
  khatmaType: Khatma['type'];
  khatmaId: Khatma['khatmaId'];
}): Part[] => {
  const result: Record<string, Part> = {};

  let options: [30 | 60 | 114 | 604, 3 | 4] | undefined;

  switch (params.khatmaType) {
    case 'JUZ':
      options = [30, 3];
      break;
    case 'HIZB':
      options = [60, 3];
      break;
    case 'SURA':
      options = [114, 4];
      break;
    case 'PAGE':
      options = [604, 4];
      break;
    default:
      break;
  }

  if (!options) {
    console.error(`Invalid khatmaType: ${params.khatmaType}`);
    return [];
  }

  params.currentParts.forEach((part) => {
    result[part.partId] = part;
  });

  for (let i = 1; i <= options[0]; i += 1) {
    const partId = i.toString().padStart(options[1], '0');
    if (!result[partId]) {
      result[partId] = {
        partId,
        khatmaId: params.khatmaId,
        status: 'OPEN',
      };
    }
  }
  return Object.values(result).sort((a, b) => a.partId.localeCompare(b.partId));
};

export default fillParts;
